<template>
  <div>
    <!-- <button class="outline-none h-full" @click="openModal()">
      <slot />
    </button> -->
    <div  @click="openModal()">
      <slot></slot>

    </div>
    <ClientOnly>
      <HeadlessTransitionRoot as="template" :show="open">
        <HeadlessDialog as="div" class="relative z-[9999]" @close="open = false">
          <HeadlessTransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </HeadlessTransitionChild>

          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
              <HeadlessTransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <HeadlessDialogPanel :class="['relative transform overflow-hidden rounded-xl bg-woodsmoke-850 text-left shadow-xl transition-all duration-500 p-4 w-full max-w-sm']">

                  <div class="text-center my-8 mb-12">
                    <h1 class="text-white uppercase tracking-widest" >Connect Wallet</h1>
                    <p class="text-sm">Choose how you want to connect to Oxbull</p>
                  </div>

                  <button @click="selectProvider('injected')" :disabled="!hasEthereum" class="w-full h-auto max-h-full p-4 m-0 min-h-0 btn bg-white/5 text-turquoise-500 hover:text-white hover:border-white/10 border-transparent grid grid-cols-4 group mb-2 disabled:border-red-500 disabled:border-opacity-20">
                    <div class="text-left col-span-3">
                      <p class="mb-0.5">Web3 Wallet</p>
                      <p class="text-xs font-normal text-white group-disabled:text-gray-500" >Works for browser and mobile wallets</p>
                    </div>

                    <div class="flex justify-end items-center space-x-2">

                      <img class="w-6 h-6" src="/metamask.svg">
                      <img class="w-6 h-6" src="/trust_wallet.svg">
                      <img class="w-6 h-6" src="/globe.svg">

                    </div>

                    <div v-if="!hasEthereum" class="col-span-full text-woodsmoke-300 text-xs font-normal pt-2 bg-white/5 rounded-md p-2 w-full text-left">
                      This browser do not have a Web3 wallet installed. Get one or use WalletConnect.
                    </div>


                  </button>

                  <button @click="selectProvider('walletconnect')" class="w-full h-auto max-h-full p-4 m-0 min-h-0 btn bg-white/5 text-turquoise-500 hover:text-white hover:border-white/10 border-transparent grid grid-cols-4 group">

                    <div class="text-left col-span-3">
                      <p class="mb-0.5">Wallet Connect</p>
                      <!-- <p class="text-xs font-normal text-white group-disabled:text-gray-500" >Works for browser and mobile wallets</p> -->
                    </div>

                    <div class="flex justify-end items-center">
                      <img class="w-6 h-6" src="/walletconnect.svg">
                    </div>

                  </button>



                  <button @click="open=false" class="w-full h-auto max-h-full p-4 m-0 min-h-0 btn btn-ghost mt-4 text-red-500 font-normal underline">Connect Later</button>
                  


                  <!--<nav :class="['w-full grid grid-cols-6 justify-center items-center min-w-[300px]']">
                    <div>
                      <button v-if="state != 'pick'" :class="['btn bg-turquoise-500 text-black hover:text-white btn-circle btn-sm']" @click="backToSelect()">
                        <Icon name="ic:round-arrow-back-ios" />
                      </button>
                    </div>
                    <div class="col-span-4 text-center text-white uppercase font-medium tracking-widest flex justify-center items-center pt-1">
                      <span>Connect Wallet</span>
                    </div>
                    <div class="w-full flex justify-end items-center">
                      <button class="btn btn-ghost btn-sm text-red-500" @click="closeModal()">
                        <Icon name="akar-icons:cross" />
                      </button>
                    </div>
                    <div v-if="state != 'pick'">
                      <div class="text-xs uppercase tracking-wider pt-1 pl-1 text-gray-500">Back</div>
                    </div>
                  </nav>

                  <section class="w-full mb-8 mt-2">
                    <div class="w-full grid grid-cols-5 justify-center items-center">
                      <div class="w-full flex justify-end items-center">
                        <div class="w-3 h-3 rounded-full -mr-1" :class="[$address ? 'bg-turquoise-500' : 'bg-woodsmoke-700']"></div>
                      </div>

                      <div class="w-full col-span-3 flex justify-center items-center">
                        <div class="h-1 w-1/2" :class="[$address ? 'bg-turquoise-500' : 'bg-woodsmoke-700']"></div>
                        <div class="h-1 w-1/2 bg-woodsmoke-700"></div>
                      </div>

                      <div class="w-full flex justify-start items-center">
                        <div class="w-3 h-3 bg-woodsmoke-700 rounded-full -ml-1"></div>
                      </div>

                      <div class="col-span-2 text-center uppercase text-sm pt-2 tracking-wider">Connect</div>

                      <div class="col-span-1"></div>

                      <div class="col-span-2 text-center uppercase text-sm pt-2 tracking-wider">Sign</div>
                    </div>
                  </section>

      
    <div class="w-full grid grid-cols-3 gap-2 gap-y-4">
      <template v-if="!$address">
        <div class="sm:col-span-1 col-span-full">
          <div class="uppercase font-semibold text-turquoise-200 tracking-widest text-xs">Step One</div>
          <div class="text-lg text-white">Connect</div>
          <div class="text-sm text-woodsmoke-400">Select how you want to connect your wallet. To use the Oxbull app, you need a Web 3 wallet.</div>
        </div>
        <div class="w-full grid grid-cols-2 gap-2 sm:col-span-2 col-span-full bg-woodsmoke-700 rounded-md p-2">
          <div class="col-span-full text-center uppercase text-turquoise-500 pl-1 tracking-widest animate animate-pulse">Select your Wallet</div>

          <div @click="selectProvider(provider)" v-for="provider in providers" class="bg-woodsmoke-600 rounded-md p-0.5 w-full grid grid-cols-4 justify-center items-center duration-300 hover:scale-105 cursor-pointer">
            <div class="w-full p-2 aspect-square overflow-hidden">
              <div class="w-full h-full flex justify-center items-center rounded">
                <img class="w-10 h-10" :src="provider.logo" alt="">
              </div>
            </div>

            <div class="col-span-3 text-white text-lg">
              {{ provider.label }}
            </div>
          </div>

        </div>
      </template>

      <template v-if="$address">
        <div class="w-full col-span-full text-center px-2 break-all">
          <div class="uppercase font-semibold text-turquoise-200 tracking-widest text-xs">Step One</div>
          <p class="text-xl uppercase font-light text-white">connected</p>
          <p class="text-sm">{{ $address }}</p>
        </div>
      </template>

      <div class="col-span-full py-4">
        <hr class="border border-woodsmoke-500/20" />
      </div>

      <div class="sm:col-span-1 col-span-full">
        <div class="uppercase font-semibold text-turquoise-200 tracking-widest text-xs">Step Two</div>
        <div class="text-lg text-white">Sign</div>
        <div class="text-sm text-woodsmoke-400">To ensure your account's security and your seamless access to our services, we ask you to sign in with your wallet. Here's why</div>
      </div>

      <div class="sm:col-span-2 col-span-full text-sm">
        <div class="w-full grid grid-cols-2 gap-4">
          <div>
            <!~~ <span class="text-3xl text-woodsmoke-300 mb-2 iconify" data-icon="mdi:shield-key"></span> ~~>
            <Icon name="mdi:shield-key" class="text-3xl text-woodsmoke-300 mb-2"></Icon>
            <strong class="text-white text-base font-light">Secure Your Account</strong>
            <p>Ensures only you access your information.</p>
          </div>

          <div>
            <!~~ <span class="text-3xl text-woodsmoke-300 mb-2 iconify" data-icon="emojione-monotone:free-button"></span> ~~>
            <Icon name="emojione-monotone:free-button" class="text-3xl text-woodsmoke-300 mb-2"></Icon>
            <strong class="text-white text-base font-light">Free and Safe</strong>
            <p>Signing is free and doesn't touch your funds.</p>
          </div>
        </div>

        <div class="mt-4 text-turquoise-500">By signing, you help us keep your experience on Oxbull.tech safe and personalized.</div>

        <div v-if="($address && !userStore.accessToken)" class="w-full flex justify-between items-center mt-8 bg-woodsmoke-700 p-2 rounded-md space-x-2">
          <div class="animate-pulse text-white text-base tracking-wide">Signature Requested<br />Please Sign to Proceed</div>

          <button @click="tryAgain()" class="flex-shrink-0 btn btn-circle btn-sm bg-woodsmoke-500 border-transparent text-turquoise-500">
            <Icon name="ic:twotone-refresh" class="text-lg" />
          </button>
        </div>
      </div>
    </div>

   -->


                </HeadlessDialogPanel>
              </HeadlessTransitionChild>
            </div>
          </div>
        </HeadlessDialog>
      </HeadlessTransitionRoot>
    </ClientOnly>
  </div>
</template>

<script setup>
const open = ref(false);
const state = ref("pick");

// import { config } from "~/wagmiConfig.js";
import { getAccount, watchConnections } from "@wagmi/core";

import { useUserStore } from "~/stores/user.js";
const userStore = useUserStore();

const { $address, $auth } = useNuxtApp()

const hasEthereum = computed( () => {
  if(window?.ethereum){
    return true
  }

  return false
  // return true
})

// const address = ref("");

const providers = [
  {
    label: "Metamask",
    logo: '/metamask.svg',
    provider: "injected",
  },
  {
    label: "TrustWallet",
    logo: '/trust_wallet.svg',
    provider: "injected",
  },
  {
    label: "WalletConnect",
    logo: '/walletconnect.svg',
    provider: "walletconnect",
  },
  {
    label: "Browser",
    logo: '/globe.svg',
    provider: "injected",
  },
]

const selectedProvider = ref(null);

// const unwatch = watchConnections(config, {
//   onChange(data) {
//     // console.log(data)
//     if (data) {
//       let _account = getAccount(config);
//       address.value = _account.address;
      
//     } else {
//       address.value = null;
//     }
//   },
// });

// onUnmounted(() => {
//   unwatch();
// });

const openModal = () => {
  open.value = true;
  state.value = "pick";
};

// Connection Logic

const selectProvider = async (provider) => {

  selectedProvider.value = provider;

  // console.log("select provider", provider);
  // let result = await userStore.auth(provider.provider);
  let result = await $auth(provider);
  console.log('========',result)
  if(result) {
    open.value = false;
  }
  // state.value = "sign";

  // let result;

};

const tryAgain = async () => {
  // let result = await userStore.auth(selectedProvider.value.provider);
  let result = await $auth(selectedProvider.value);
  if(result) {
    open.value = false;
  }
};

const closeModal = () => {
  open.value = false;
};

</script>

<style></style>
